section {
  padding: rem-calc(35 0 25 0);
  @include breakpoint(medium) {
    padding: rem-calc(70 0 50 0);
  }
}

#intro {
  overflow: hidden;
  background-color: $sea;
  color: $white;
  img {
    margin: rem-calc(10 0);
    max-width: rem-calc(320);
    width: 50%;
    border: 7px solid $white;
    border-radius: 50%;
    @include breakpoint(medium) {
      margin: rem-calc(20 0);
      width: 100%;
    }
  }
}

#service {
  text-align: center;
  h1 {
    color: $sea;
  }
  p {
    margin-bottom: rem-calc(30);
  }
}

#sectors {
  background-color: $ghost;

  h1 {
    margin-bottom: rem-calc(40);
  }

  .sector-icons > div {
    //margin-bottom: rem-calc(20);
    height: rem-calc(70);
    i {
      display: block;
      float: left;
      margin-right: rem-calc(15);
      width: rem-calc(50);
      height: rem-calc(50);
      background-color: $sea;
      border-radius: 50%;
      font-size: rem-calc(28);
      color: $white;
      text-align: center;
      line-height: rem-calc(50);
      &.icon-treppenbau, &.icon-blech {
        font-size: rem-calc(25);
      }
    }
    p {
      line-height: 1.3;
      &.oneline {
        padding-top: rem-calc(10);
      }
    }
  }
}

#contact {
  padding-bottom: rem-calc(70);
  background: $sea url(../img/contact-bg.jpg) no-repeat top center;
  background-size: cover;
  color: $white;
  text-align: center;

  .box {
    display: inline-block;
    margin-top: rem-calc(20);
    padding: rem-calc(25 35 10 25);
    background-color: $ghost;
    color: $text;
    text-align: left;
  }
}

#partner {
  background: url(../img/partner-bg.gif) center center;
  text-align: center;
  .logo {
    display: inline-block;
    margin: rem-calc(20 0 15 0);
    padding: rem-calc(20 10);
    background-color: $white;
    border: 1px solid $smoke;
    text-align: center;
    transition: all 400ms ease;
    @include breakpoint(medium) {
      padding: rem-calc(40);
    }
    &:hover {
      background-color: $ghost;
    }
    img {
      max-height: rem-calc(72);
    }
  }
  .link a {
    font-size: 90%;
    color: $text;
  }
}

#text {
  h1 {
    margin-bottom: rem-calc(30);
  }
}
#back {
  padding: rem-calc(15 0);
  text-align: center;
  &.top {
    background-color: $sea;
    a {
      color: $white;
    }
  }
  &.bottom {
    background-color: $ghost;
  }
}



@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.animated {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  &.delay1 {
    -webkit-animation-delay: 400ms;
    animation-delay: 400ms;
  }
  &.delay2 {
    -webkit-animation-delay: 800ms;
    animation-delay: 800ms;
  }
}
