header {
  padding: rem-calc(20 0);
  border-bottom: 8px solid $ghost;
  img {
    margin: rem-calc(0 0 20 0);
    max-height: rem-calc(50);
    @include breakpoint(medium) {
      margin: rem-calc(14 0 0 0);
      max-height: rem-calc(71);
    }
  }
}

footer {
  padding: rem-calc(20 0);
  background-color: $sea;
  color: $white;
  font-size: 90%;

  .meta {
    @include breakpoint(small only) {
      padding-top: rem-calc(10);
    }
  }
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}


h1, h2, h3, h4, h5, h6 {
  text-align: center;
}
a {
  &:hover {
    text-decoration: underline;
  }
}
p {
  @include breakpoint(medium) {
    @include breakpoint(small only) {
      padding-top: rem-calc(10);
    }
  }
}
.f-highlight {
  color: $sea;
}
.ptop-5 {
  padding-top: rem-calc(5);
}
.icon {
  display: inline-block;
  i {
    display: inline-block;
    width: 25px;
  }
}


#cookie-bar {background:#373737; height:auto; line-height:24px; color:$ghost; text-align:center; padding:7px 0;}
#cookie-bar.fixed {position:fixed; bottom:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:#60b537;}
#cookie-bar .cb-enable:hover {background:#6cca3d;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}
