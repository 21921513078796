@font-face {
  font-family: 'sip';
  src:  url('../font/sip.eot?ivpex0');
  src:  url('../font/sip.eot?ivpex0#iefix') format('embedded-opentype'),
  url('../font/sip.ttf?ivpex0') format('truetype'),
  url('../font/sip.woff?ivpex0') format('woff'),
  url('../font/sip.svg?ivpex0#SIP') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sip' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\f05a";
}
.icon-phone:before {
  content: "\f095";
}
.icon-fax:before {
  content: "\f1ac";
}
.icon-zaunbau:before {
  content: "\f111";
}
.icon-schlosser:before {
  content: "\f109";
}
.icon-mail:before {
  content: "\f101";
}
.icon-tueren:before {
  content: "\f10e";
}
.icon-gelaender:before {
  content: "\f116";
}
.icon-montage:before {
  content: "\f10c";
}
.icon-reparaturen:before {
  content: "\f115";
}
.icon-treppenbau:before {
  content: "\f112";
}
.icon-blech:before {
  content: "\f118";
}
.icon-schweissen:before {
  content: "\f127";
}