.aspect {
  display: block;
  height: 0;
  padding: 0;
  position: relative;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.s-aspect-1-1 {
  padding-bottom: 100%;
}
.s-aspect-5-4 {
  padding-bottom: 80%;
}
.s-aspect-4-3 {
  padding-bottom: 75%;
}
.s-aspect-3-2 {
  padding-bottom: 66.6667%;
}
.s-aspect-16-10 {
  padding-bottom: 62.5%;
}
.s-aspect-16-9 {
  padding-bottom: 56.25%;
}
.s-aspect-2-1 {
  padding-bottom: 50%;
}
.s-aspect-21-9 {
  padding-bottom: 42.8571%;
}
.s-aspect-11-4 {
  padding-bottom: 36.3636%;
}

.m-aspect-1-1 {
  padding-bottom: 100%;
}
.m-aspect-5-4 {
  padding-bottom: 80%;
}
.m-aspect-4-3 {
  padding-bottom: 75%;
}
.m-aspect-3-2 {
  padding-bottom: 66.6667%;
}
.m-aspect-16-10 {
  padding-bottom: 62.5%;
}
.m-aspect-16-9 {
  padding-bottom: 56.25%;
}
.m-aspect-2-1 {
  padding-bottom: 50%;
}
.m-aspect-21-9 {
  padding-bottom: 42.8571%;
}
.m-aspect-11-4 {
  padding-bottom: 36.3636%;
}

.l-aspect-1-1 {
  padding-bottom: 100%;
}
.l-aspect-5-4 {
  padding-bottom: 80%;
}
.l-aspect-4-3 {
  padding-bottom: 75%;
}
.l-aspect-3-2 {
  padding-bottom: 66.6667%;
}
.l-aspect-16-10 {
  padding-bottom: 62.5%;
}
.l-aspect-16-9 {
  padding-bottom: 56.25%;
}
.l-aspect-2-1 {
  padding-bottom: 50%;
}
.l-aspect-21-9 {
  padding-bottom: 42.8571%;
}
.l-aspect-11-4 {
  padding-bottom: 36.3636%;
}
